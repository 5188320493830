export default {
  isLiteral: true,
  inserted: (el, binding) => {
    let f = () => {
      let rect = el.getBoundingClientRect();
      let inView =
        rect.width > 0 &&
        rect.height > 0 &&
        rect.top >= 0 &&
        rect.bottom <=
          (window.innerHeight + 200 || document.documentElement.clientHeight);
      if (inView) {
        el.classList.add(binding.value);
        setTimeout(() => {
          el.classList.remove(
            "showElement",
            "hidden",
            "hidden-down",
            "hidden-right",
            "hidden-left"
          );
        }, 800);
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
    f();
  }
};
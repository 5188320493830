export default {
  bind(el, binding) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event); // Llama a la función pasada en el valor de la directiva
      }
    };
    setTimeout(() => {
      document.addEventListener('click', el.clickOutsideEvent);
    }, 100);
  },
  unbind(el) {
    document.removeEventListener('click', el.clickOutsideEvent);
  },
};